import Vue from 'vue'
import Vuex from 'vuex'
import menu from "./menu";
import user from "@/store/userInfo";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        menu,
        user,
    }
})
