<template>
  <div class="tags">
    <el-tag
        class="tag"
        size="medium "
        v-for="(tag, index) in dynamicTags"
        :key="`tag-${tag.path}-${index}`"
        :effect="activePath === tag.path ? 'dark' : 'plain'"
        :closable="!!index"
        disable-transitions
        @close="handleClose(tag)"
        @click="goTo(tag)"
        @contextmenu.native.prevent="rightClick($event, index)"
    >
      <i :class="activePath === tag.path ? 'point' : ''"/>
      {{ tag.title }}
    </el-tag>
    <TagMenu v-show="isShowMenu" :clientX="clientX" :clientY="clientY" :tag-index="tagIndex"
             :tags-length="dynamicTags.length" @click-menu="clickMenu"/>
  </div>
</template>
<script>
import TagMenu from "@/layout/main/components/TagMenu.vue";
import {mapMutations, mapState} from "vuex";

export default {
  name: "Tags",
  components: {TagMenu},
  data() {
    return {
      activePath: '/home',
      homePageInfo: {
        name: 'HomeView',
        path: '/home',
        title: '首页',
        keepAlive: true,
      },
      isShowMenu: false,
      clientX: 0,
      clientY: 0,
      tagIndex: 0,
    }
  },
  computed: {
    ...mapState({dynamicTags: state => state.menu.dynamicTags})
  },
  watch: {
    $route: {
      handler(val) {
        this.activePath = val.path
        if (this.dynamicTags.findIndex(item => item.path === val.path) === -1) {
          this.setDynamicTags([...this.dynamicTags, {
            name: val.name,
            path: val.path,
            title: val.meta.title,
            keepAlive: val.meta.keepAlive,
          }])
        }
      },
      immediate: true
    }
  },
  mounted() {
    document.addEventListener('click', () => {
      this.hiddenMenu()
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', () => {
      this.hiddenMenu()
    })
  },
  methods: {
    ...mapMutations({setDynamicTags: 'menu/setDynamicTags'}),
    /**
     * ## 点击标签跳转路由
     * @param tag 标签对象
     */
    goTo(tag) {
      this.$router.push(tag.path)
      this.hiddenMenu()
    },
    /**
     * ## 关闭标签
     * @param tag 标签对象
     */
    handleClose(tag) {
      const arr = [...this.dynamicTags]
      arr.splice(this.dynamicTags.indexOf(tag), 1)
      this.setDynamicTags(arr)
      if (tag.path === this.activePath) {
        this.$router.push(this.dynamicTags[this.dynamicTags.length - 1].path)
      }
      this.hiddenMenu()
    },
    /**
     * ## 标签右键菜单
     * @param event 事件对象
     * @param index 标签索引
     */
    rightClick(event, index) {
      this.isShowMenu = true
      this.clientX = event.clientX
      this.clientY = event.clientY
      this.tagIndex = index
    },
    /**
     * ## 隐藏菜单
     */
    hiddenMenu() {
      this.isShowMenu = false
    },
    /**
     * ## 点击菜单选项
     * @param {number} index 菜单选项索引
     */
    clickMenu(index) {
      switch (index) {
        case 0:
          // 刷新
          this.$EventBus.$emit('refresh-view', this.dynamicTags[this.tagIndex], this.tagIndex)
          break
        case 1:
          // 关闭当前
          this.setDynamicTags([...this.dynamicTags.filter(item => item.path !== this.dynamicTags[this.tagIndex].path)])
          if (this.dynamicTags.findIndex(item => item.path === this.activePath) === -1) {
            this.$router.push(this.dynamicTags[this.dynamicTags.length - 1].path)
          }
          break
        case 2:
          // 关闭其他
          this.setDynamicTags([this.homePageInfo, ...this.dynamicTags.filter(item => item.path === this.dynamicTags[this.tagIndex].path)])
          this.$router.push(this.dynamicTags[1].path)
          break
        case 3:
          // 关闭左侧
          this.setDynamicTags([this.homePageInfo, ...this.dynamicTags.filter((_, $index) => this.tagIndex <= $index)])
          if (this.dynamicTags.findIndex(item => item.path === this.activePath) === -1) {
            this.$router.push(this.dynamicTags[this.dynamicTags.length - 1].path)
          }
          break
        case 4:
          // 关闭右侧
          this.setDynamicTags([...this.dynamicTags.filter((_, $index) => this.tagIndex >= $index)])
          if (this.dynamicTags.findIndex(item => item.path === this.activePath) === -1) {
            this.$router.push(this.dynamicTags[this.dynamicTags.length - 1].path)
          }
          break
        case 5:
          // 关闭全部
          this.setDynamicTags([this.homePageInfo])
          this.$router.push('/home')
          break
      }
    }
  }
}
</script>



<style scoped>
.tags {
  padding: 0 10px;
  display: flex;
  gap: 6px;
}

.tag {
  cursor: pointer
}

.point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: white;
}
</style>