module.exports = {
    // 项目名称
    title: 'AI华智开放平台',
    // 请求地址
    baseURL: 'https://test.oouu.cc:59',
    // 请求超时时长
    timeout: 1000,
    // 不需要token白名单
    whiteList: ['/','/announcement','/announcement/component/notification','/login', '/index', '/404'],
    // localStorage保存token的key
    tokenKey: 'token',
    // localStorage保存用户信息的key
    userInfoKey: 'localStorage-userInfo-key',
    // 缓存组件的最大数量
    maxCachedComponents: 10,
}