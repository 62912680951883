import router from "@/router";
import {getMenuList, getFlatMenuList, routeExists} from '@/utils/tools/RouteTools'

export default {
    namespaced: true,
    state: {
        // 侧边栏是否折叠
        isCollapse: false,
        // 菜单列表
        menuList: [],
        // tags 标签列表
        dynamicTags: [{
            name: 'HomeView',
            path: '/home',
            title: '首页',
            keepAlive: true,
        }],
    },
    getters: {},
    mutations: {
        /**
         * ## 设置侧边栏折叠状态
         * @param {Object} state - 应用程序状态对象
         */
        setCollapse(state) {
            state.isCollapse = !state.isCollapse;
        },
        /**
         * ## 设置菜单列表
         * @param {Object} state - 应用程序状态对象
         * @param {Array} data - 原始菜单数据
         */
        setMenuList(state, data) {
            // 获取处理后的菜单列表
            const menuList = getMenuList(data)
            // 获取扁平化后的菜单列表
            const flatMenuList = getFlatMenuList(menuList)
            // 获取处理后的全屏菜单列表
            const fullMenuList = getMenuList(data, '/full')
            // 获取扁平化后的全屏菜单列表
            const flatFullMenuList = getFlatMenuList(fullMenuList)
            // 获取当前所有路由
            const currentRoutes = router.getRoutes();
            // 遍历菜单列表，如果当前路由不存在，则添加
            flatMenuList.forEach(item => {
                if (!routeExists(currentRoutes, item)) {
                    router.addRoute('main', item);
                }
            })
            // 遍历全屏菜单列表，如果当前路由不存在，则添加
            flatFullMenuList.forEach(item => {
                if (!routeExists(currentRoutes, item)) {
                    router.addRoute('full', item);
                }
            })
            // 设置菜单列表
            state.menuList = menuList;
        },
        /**
         * ## 设置标签列表
         * @param {Object} state - 应用程序状态对象
         * @param {Array} data - 标签列表数据
         */
        setDynamicTags(state, data) {
            state.dynamicTags = data;
        },
    },
    actions: {
        /**
         * ## 更新菜单列表
         * 此函数用于在状态管理中更新菜单列表数据
         * 它首先检查当前菜单列表是否为空，如果非空则不执行任何操作
         * 如果菜单列表为空，则异步加载菜单数据，并通过mutation更新到状态中
         * @param {Object} context - Vuex的上下文对象，包含commit和state方法
         * @param {Object} payload - 传递给该action的负载，如果不需要负载则可以省略
         */
        updateMenuList({commit, state}, payload) {
            // 如果菜单列表已经存在，则不执行操作
            if (state.menuList.length !== 0) return
            // 异步获取菜单列表
            const data = [
                {
                    name: 'Management',
                    path: 'management',
                    meta: {
                        title: '管理',
                        icon: 'el-icon-menu'
                    },
                    children: [
                        {
                            name: 'TradingCenter',
                            path: 'tradingCenter',
                            component: () => import(/* webpackChunkName: "user" */ '@/views/management/TradingCenter.vue'),
                            meta: {
                                title: '交易中心',
                                icon: 'el-icon-coin',
                                keepAlive: true,
                            },
                        },
                        {
                            name: 'DigitalMan',
                            path: 'digitalMan',
                            component: () => import(/* webpackChunkName: "role" */ '@/views/management/DigitalMan.vue'),
                            meta: {
                                title: '数字人',
                                icon: 'el-icon-camera',
                                keepAlive: true,
                            },
                        },
                        {
                            name: 'TtsAudio',
                            path: 'ttsAudio',
                            component: () => import(/* webpackChunkName: "permission" */ '@/views/management/TtsAudio.vue'),
                            meta: {
                                title: 'tts音频',
                                icon: 'el-icon-mic',
                                keepAlive: true,
                            },
                        },
                        {
                            name: 'AccInformation',
                            path: 'accInformation',
                            component: () => import(/* webpackChunkName: "permission" */ '@/views/management/AccountInformation.vue'),
                            meta: {
                                title: '账户信息',
                                icon: 'el-icon-user',
                                keepAlive: true,
                            },
                        }
                    ]
                },
            ]
            // 设置菜单列表
            commit('setMenuList', payload || data);
        }
    }
};