import {tokenKey, userInfoKey} from '@/utils/config'

export default {
    namespaced: true,
    state: {
        // token: localStorage.getItem(token) || "",
        // userInfo: JSON.parse(localStorage.getItem(userInfoKey)) || {id: "", name: "", avatar: ""},
    },
    getters: {},
    mutations: {
        setToken(state, data) {
            state.token = data;
            // localStorage.setItem(tokenKey, JSON.stringify(data));
        },
        setUserInfo(state, data) {
            state.userInfo = data;
            // localStorage.setItem(userInfoKey, JSON.stringify(data));
        }
    },
    actions: {
        /**
         * ## 更新token
         * 此函数旨在异步获取并设置token
         * @param {Object} context - 包含`commit`和`state`属性的对象
         * @param {Object} payload - 传递给该函数的负载，本例中未使用
         */
        updateToken({commit, state}, payload) {
            // 如果token已经存在，则不执行操作
            if (state.token) return
            // 异步获取token
            const token = '123456789';
            // 将获取到的token提交到状态管理中
            commit('setToken', payload || token);
        },
        /**
         * ## 更新用户信息
         * 此函数用于更新应用中的用户信息，当用户信息已存在时，不会重复更新
         * @param {Object} context - 包含commit和state方法的上下文对象
         * @param {Object} payload - 传递给该函数的数据负载，在此场景中未使用
         */
        updateUserInfo({commit, state}, payload) {
            // 如果用户信息已经存在，则不执行操作
            if (state.userInfo.id) return
            // 异步获取用户信息
            const data = {
                id: 1,
                name: 'admin',
                avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80'
            }
            // 将获取到的用户信息提交到Vuex的store中
            commit('setUserInfo', payload || data);
        },
        /**
         * ## 退出登录功能
         * 该函数通过Vuex的commit方法来修改状态，以实现用户退出登录
         * 主要包括清空用户的token和用户信息
         * @param {Object} context - Vuex的上下文对象，包含commit方法
         */
        logout({commit}) {
            // 清空token
            commit('setToken', '');
            // 清空用户信息
            commit('setUserInfo', {id: '', name: '', avatar: ''});
        }
    }
};