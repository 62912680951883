import axios from "axios";
import {baseURL, timeout} from '@/utils/config'
import Vue from "vue";
import {emitter} from '@/utils/tools/Emitter'
const instance = axios.create({
    baseURL,
    timeout,
    headers: {
        "Content-Type": "application/json;charset=utf-8"
    }
})

// 请求拦截器
instance.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
    const errorMap = {
        400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
        401: '用户没有权限（令牌、用户名、密码错误）。',
        403: '用户得到授权，但是访问是被禁止的。',
        404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
        406: '请求的格式不可得。',
        410: '请求的资源被永久删除，且不会再得到的。',
        422: '当创建一个对象时，发生一个验证错误。',
        500: '服务器发生错误，请检查服务器。',
        502: '网关错误。',
        503: '服务不可用，服务器暂时过载或维护。',
        504: '网关超时。',
        505: 'HTTP版本不支持该请求'
    }
    // token 过期
    if (response.status === 401) {
        // 触发退出登录事件
        emitter.$emit('API_UN_AUTH')
    }
    if (response.status in errorMap) {
        Vue.prototype.$message({
            message: errorMap[response.status],
            type: 'error'
        })
        return Promise.reject(response);
    } else {
        return response
    }
}, error => {
    return Promise.reject(error)
})

/**
 * ## get方法，对应get请求
 * @param url 请求的url地址
 * @param params 请求时携带的参数
 */
Vue.prototype.$get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * ## post方法，对应post请求
 * @param {string} url 请求的url地址
 * @param {Object} params 请求时携带的参数
 */
Vue.prototype.$post = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.post(url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * ## put方法，对应put请求
 * @param url 请求的url地址
 * @param params 请求时携带的参数
 */
Vue.prototype.$put = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.put(url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * ## delete方法，对应delete请求
 * @param url 请求的url地址
 * @param params 请求时携带的参数
 */
Vue.prototype.$delete = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.delete(url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * ## patch方法，对应patch请求
 * @param url 请求的url地址
 * @param params 请求时携带的参数
 */
Vue.prototype.$patch = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.patch(url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}