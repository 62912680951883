import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layout/main/index.vue'
import FullLayout from '../layout/full/index.vue'
import store from '@/store';
import {emitter} from '@/utils/tools/Emitter'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        redirect: '/home',
        component: MainLayout,
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    title: '首页',
                    titles: ['首页'],
                    keepAlive: true,
                },
                component: () => import(/* webpackChunkName: "index" */ '../views/HomeView.vue')
            }
        ]
    },
    {
        path: '/full',
        name: 'full',
        component: FullLayout,
        children: []
    },
    {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "login" */ '../views/index/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
    },
    {
        path: '/announcement',
        name: 'announcement',
        component: () => import(/* webpackChunkName: "login" */ '../views/announcement/announcement.vue')
    },
    {
        path: '/announcement/component/notification',
        name: 'notification',
        component: () => import(/* webpackChunkName: "login" */ '../views/announcement/component/notification.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/error/404.vue'),
    }
]

const router = new VueRouter({
    routes
})

// 解决push重复路由报错
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originPush.call(this, location).catch(err => err)
}

// 监听退出登录事件，跳转登录页，清空用户信息
emitter.$on('API_UN_AUTH', ()=>{
    router.push('/index').then(()=>{
        store['dispatch']('user/logout');
    });
})
export default router
