import Vue from 'vue'
import {
    Button,
    Input,
    Select,
    Option,
    Tag,
    Form,
    FormItem,
    Tabs,
    Table,
    TableColumn,
    Tree,
    Pagination,
    Card,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Result,
    Breadcrumb,
    BreadcrumbItem,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Popconfirm,
    Message,
    MessageBox,
    Checkbox,
    CheckboxGroup,
    Drawer,
    Tooltip,
    Link,
    Switch,
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Card)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Result)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Popconfirm)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Card)
Vue.use(Link)
Vue.use(Switch)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm;