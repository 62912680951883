<template>
  <div class="navBar" :class="{ isCollapse: isCollapse }">
    <div class="logo-box" @click="goIndex('/index')">
      <img class="logo" src="../../assets/logo.png" alt="logo">
      <span v-show="!isCollapse" class="title">{{ title }}</span>
    </div>
    <el-menu 
      :collapse="isCollapse" 
      :default-active="$route.path"
      :background-color="backgroundColor"
      :text-color="textColor" 
      :active-text-color="activeTextColor" 
      :collapse-transition="false" 
      :unique-opened="false" 
      :default-openeds="defaultOpeneds"
      @select="handleSelect" 
      router
    >
      <MenuContext :list="menuList" />
    </el-menu>
  </div>
</template>

<script>
import { title } from "@/utils/config";
import MenuContext from "@/layout/main/components/MenuContext.vue";
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "NavBar",
  components: { MenuContext },
  data() {
    return {
      title: title,
      backgroundColor: '#545c64',
      textColor: '#fff',
      activeTextColor: '#ffd04b',
      activeMenu: '',
      defaultOpeneds: ['/management', '/index', '/system']
    }
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.menu.isCollapse,
      menuList: (state) => state.menu.menuList,
    })
  },
  created() {
    this.activeMenu = this.$route.path
  },
  mounted() {
    this.defaultOpeneds = this.getMenuIndexes(this.menuList);
  },
  methods: {
    /**
     * ## 菜单激活回调
     * @param {string} index 选中菜单项的 index 属性
     * @param {string[]} indexPath 选中菜单项的 index path 数据数组
     */
    handleSelect(index, indexPath) {
      router.push(index)
    },
    goIndex(path){
      // this.$router.push(path)
    },
    getMenuIndexes(menuList) {
      let indexes = [];
      menuList.forEach(menu => {
        if (menu.path) {
          indexes.push(menu.path);
        }
        if (menu.children && menu.children.length) {
          indexes = indexes.concat(this.getMenuIndexes(menu.children));
        }
      });
      return indexes;
    }
  }
}
</script>



<style scoped lang="less">
.navBar {
  width: 200px;
  height: 100%;
  background-color: v-bind(backgroundColor);
  transition: all .3s ease;
}

.el-menu {
  border: none;
}

.navBar.isCollapse {
  width: 64px;
}

.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
  .title{
    color: #fff;
    line-height: 50px;
    margin-left: 5px;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
}
</style>