<template>
  <section class="main-layout">
    <NavBar />
    <main class="right">
      <HeaderView />
      <ContentView />
    </main>
  </section>
</template>
<script>
import NavBar from "@/layout/main/NavBar.vue";
import HeaderView from "@/layout/main/HeaderView.vue";
import ContentView from "@/layout/main/ContentView.vue";

export default {
  name: "index",
  components: { ContentView, HeaderView, NavBar },
}
</script>



<style scoped>
.main-layout {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.right {
  flex: 1;
}
</style>