import Vue from 'vue'
// 清除默认样式
import 'reset-css'
import App from './App.vue'
import router from './router'
import './router/BeforEach'
import store from './store'
// 按需引入element-ui
import './plugins/element.js'
// 引入axios
import './utils/axios'

// 引入
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

// 引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 关闭 Vue 的生产提示
Vue.config.productionTip = false
// 全局事件总线
Vue.prototype.$EventBus = new Vue();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
