import router from "./index";
import {whiteList, tokenKey} from '@/utils/config'
import store from "@/store";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// 路由前置守卫
router.beforeEach(async (to, from, next) => {
    // 开始进度条加载
    NProgress['start']();
    await store.dispatch('menu/updateMenuList')
    const token = localStorage.getItem(tokenKey)
    const hasRoute = router.getRoutes().filter(item => item.path === to.path).length === 0
    if (whiteList.includes(to.path)) {
        next()
    } else if (token) {
        if (to.path === '/index') {
            next('/')
        }
        // 如果路由匹配不到，则更新菜单
        if (to.matched.length <= 1) {
            if (hasRoute) {
                next('/404')
            } else {
                next(to.path);
            }
        } else {
            next()
        }
    } else {
        next('/index')
    }
    // 结束进度条加载
    NProgress['done']();
})