<template>
  <ul class="tagMenu" :style="{ left: clientX + 'px', top: clientY + 'px' }">
    <li v-show="isShowLi(index)" class="tagMenuItem" v-for="(item, index) in menuList" :key="index + 'li'"
      @click="handleClick(index)">
      <i :class="item.icon" />
      {{ item.text }}
    </li>
  </ul>
</template>
<script>
export default {
  name: "TagMenu",
  props: {
    // 当前鼠标X坐标
    clientX: {
      type: Number,
      default: 0
    },
    // 当前鼠标Y坐标
    clientY: {
      type: Number,
      default: 0
    },
    // 当前标签下标
    tagIndex: {
      type: Number,
      default: 0
    },
    // 标签数组长度
    tagsLength: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      menuList: [
        {
          text: '刷新当前',
          icon: 'el-icon-refresh'
        },
        {
          text: '关闭当前',
          icon: 'el-icon-close'
        },
        {
          text: '关闭其他',
          icon: 'el-icon-circle-close'
        },
        {
          text: '关闭左侧',
          icon: 'el-icon-back'
        },
        {
          text: '关闭右侧',
          icon: 'el-icon-right'
        },
        {
          text: '全部关闭',
          icon: 'el-icon-circle-close'
        },
      ]
    }
  },
  methods: {
    /**
     * ## 点击右键菜单选项
     * @param {number} index 右键菜单栏选项索引
     */
    handleClick(index) {
      this.$emit('click-menu', index)
    },
    /**
     * ## 判断右键菜单选项显示情况
     * @param {number} index 右键菜单栏选项索引
     * @returns {boolean} true：显示，false：隐藏
     */
    isShowLi(index) {
      if (this.tagsLength === 1) {
        return index === 0;
      }
      if (this.tagIndex === 0) {
        return index === 0 || index === 5;
      }
      if (this.tagIndex === 1 && this.tagIndex === this.tagsLength - 1) {
        return index === 0 || index === 1;
      } else if (this.tagIndex === 1 && this.tagsLength !== this.tagsLength - 1) {
        return index !== 3;
      }
      if (this.tagIndex === this.tagsLength - 1) {
        return index !== 4;
      }
      return true;
    }
  }
}
</script>



<style scoped>
.tagMenu {
  background-color: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 999;
}

.tagMenuItem {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px;
}

.tagMenuItem:hover {
  background-color: #eeeeee;
}
</style>