<template>
  <div :class="{isCollapse:isCollapse&&!parent}">
    <template v-for="(item, index) in list">
      <el-submenu v-if="('children' in item) && !item.hidden" :index="item.path" :key="index + 'submenu'">
        <template #title>
          <i :class="item.meta.icon"/>
          <span>{{ item.meta.title }}</span>
        </template>
        <MenuContext :list="item.children" :parent="list"/>
      </el-submenu>
      <el-menu-item v-else-if="!('children' in item) && !item.hidden" :index="item.path" :key="index + 'menu-item'">
        <i :class="item.meta.icon"/>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
import {mapState} from "vuex";
export default {
  name: "MenuContext",
  props: {
    list: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    parent: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.menu.isCollapse
    })
  },
}
</script>



<style scoped>
.isCollapse .el-submenu__title span {
  display: none;
}
</style>