<template>
  <div style="padding: 20px">
    <keep-alive :include="includeArr" :max="maxCachedComponents()">
      <router-view :key="cacheSwitch || key" />
    </keep-alive>
  </div>
</template>
<script>
import { maxCachedComponents } from "@/utils/config";
import { mapMutations, mapState } from "vuex";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export default {
  name: "ContentView",
  data() {
    return {
      cacheSwitch: false,
    }
  },
  computed: {
    ...mapState({
      dynamicTags: (state) => state.menu.dynamicTags,
    }),
    // 需要缓存的页面名称数组，Tips: 需要缓存的页面名称，需要缓存的页面需要设置 keepAlive: true
    includeArr() {
      let arr = []
      this.dynamicTags.forEach(item => {
        if (item.keepAlive) {
          // Tips：路由中的 name 属性和页面组件内的 name 属性一致
          arr.push(item.name)
        }
      })
      return arr
    },
    // 路由变化时，重新计算keepAlive
    key() {
      return this.$route.fullPath
    }
  },
  created() {
    this.$EventBus.$on('refresh-view', (data, index) => {
      this.refreshView(data, index)
    })
  },
  methods: {
    ...mapMutations({ setDynamicTags: 'menu/setDynamicTags' }),
    /**
     * ## 刷新页面，重置keepAlive
     * 需要缓存 && 已缓存的路由，先删除缓存，再添加到缓存数组中；
     * 不需要缓存 || 未缓存的路由直接刷新，直接刷新。
     * @param {Object} data tag信息对象
     * @param {number} index 当前tag的索引
     */
    refreshView(data, index) {
      // 开始进度条加载
      NProgress['start']();
      // 是否为当前页面
      const isCurrent = data.path === this.$route.path
      // 先将key替换，相当于一个新的页面
      isCurrent && (this.cacheSwitch = new Date().getTime())
      // 别除当前路由
      let arr = [...this.dynamicTags]
      arr.splice(index, 1)
      this.setDynamicTags(arr)
      this.$nextTick(() => {
        // 加回当前路由剔除临时key
        arr.splice(index, 0, data)
        this.setDynamicTags(arr)
        isCurrent && (this.cacheSwitch = false)
      })
      // 结束进度条加载
      NProgress['done']();
    },
    // 最大缓存组件数量
    maxCachedComponents() {
      return maxCachedComponents
    }
  }
}
</script>



<style scoped />